.flex-container {
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    height: 100%;
}

.line-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.text-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text-button-container > p {
    max-width: 500px;
}

.column {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

@media screen and (min-width: 800px) {
    .features {
        display: flex;
        min-width: 762px;
        max-width: 1200px;

    }

    .column {
        width: 450px;
    }

    .column > h4 {
        padding: 15px 0;
        margin: unset;

    }

    .column > p {
        margin: unset;

    }
}

@media screen and (max-width: 800px) {
    .features {
        display: flex;
        flex-direction: column;
        gap: 40px 0;
        max-width: 300px;
    }

    .column > h4 {
        padding: 15px 0;
    }
    .column > h4, .column > p {
        margin: unset;
    }
}
.button-row {
    width: 100%;
    display: flex;
    gap: 0 10%;
}

.button-row > button {
    min-width: 120px;
    max-width: 200px;
    font-weight: bold;
}

.button-row > .secondary {
    margin-left: auto;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.3) 0 19px 38px, rgba(0, 0, 0, 0.22) 0 15px 12px;
    border-radius: 10px;
    height: 300px;
    width: 400px;
}

.content > h1 {
    width: 300px;
    text-align: center;
}

.content > h1, img {
    cursor: pointer;
}

.features {
    flex-direction: column;
    margin: 0 auto;
}
