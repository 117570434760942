.bubble {
    border-radius: 50%;
    width: 33px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1px;
}

.bubble .text {
    font-weight: bold;
    color: white;
}