.toolbar {
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    z-index: 4;
    background-color: #EEEEEE;
    border-radius: 50px;
    box-shadow: rgba(0, 0, 0, 0.19) 0 10px 20px, rgba(0, 0, 0, 0.23) 0 6px 6px;
    /*Prevent "text highlighting" of images*/
    user-select: none;
}


.bottom {
    margin: 0 auto;
    bottom: 15px;
    left: 0;
    right: 0;
    max-width: 650px;
    padding: 15px;
    gap: 0 20px;
}

/*Collapse separators for mobile view*/
@media screen and (max-width: 500px) {
    .bottom hr {
        width: 0;
        height: 0;
        display: none;
        margin: 0;
    }

    .bottom {
        max-width: 100vw;
    }
}

.left {
    flex-direction: column;
    margin: auto 0;
    max-height: 400px;
    bottom: 0;
    top: 0;
    left: 15px;
    padding: 15px;
    gap: 20px 0;
}

.left img {
    height: 100%;
}

svg, img {
    cursor: pointer;
}


@keyframes fadeInFromComplete {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOutComplete {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
