.page-title {
    padding: 2.5rem 0;
}
.page-title h1 {
    text-align: center;
    color: #494848;
}

.item-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding: 10px;
    gap: 50px;
}

.item {
    padding: 10px 20px;
    text-align: center;
    border: 1px solid lightgray;
    border-radius: 7px;
    text-decoration: none;
}

.item-title {
    font-weight: bold;
    text-align: center;
    padding-bottom: 10px;
}

.item .users-container {
    justify-content: center;
}
