.button {
    border-radius: 100px;
    border: none;
    min-height: 35px;
    color: #ECEDED;
}

.grow {
    flex: 1 1 50%;
}

.primary {
    background-color: #FF9314;
}

.secondary {
    background-color: #25282C;
}