.loading-icon {
    background-color: transparent;
    position: fixed;
    top: calc(50% - 56px);
    left: 50%;
    animation: load 2s infinite;
}

.background {
    position: absolute;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
}

@keyframes load {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
