#canvas-container {
    background-color: gray;
    width: 100%;
    height: 100vh;
    display: grid;
    position: relative;
    justify-content: center;
    padding: 20px 0;

}

.form-container {
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    gap: 1rem;

}

canvas {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
}

#canvas {
    z-index: 2;
}

#animation-layer {
    /* This sits behind the transparent drawing canvas */
    z-index: 1;
    background: white;
}

#value {
    background-color: #C4C4C4C4;
    padding: 5px;
}

.container {
    position: fixed;
    min-width: 100%;
    min-height: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
}

.box {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;

    left: 50%;
    bottom: 50%;
    border-radius: 10px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.3) 0 19px 38px, rgba(0, 0, 0, 0.22) 0 15px 12px;
    width: 300px;
    height: 250px;
    margin-left: -150px;
    margin-bottom: -137.5px;
}

.box > p {
    margin: 15px 30px 15px 30px;
    padding: 5px;
}

.box > p {
    text-align: center;
}

.inline-copy {
    display: flex;
}

.inline-copy > button {
    height: 28px;
    border-radius: 0;
}
