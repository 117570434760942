
.marker-icon {
    transform: scaleX(-1);
    animation: 2.5s linear 0s infinite normal forwards running marker-icon-animation;
    transform-box: fill-box;
    transform-origin: 50% 50%;

}

@keyframes marker-icon-animation {
    0% {
        transform: translateX(0px) scaleX(-1);
    }
    8% {
        transform: translateX(50px) scaleX(-1);
    }
    16% {
        transform: translateX(90px) scaleX(-1);
    }
    24% {
        transform: translateX(145px) scaleX(-1);
    }
    32% {
        transform: translateX(0px) translateY(20px) scaleX(-1);
    }
    40% {
        transform: translateX(50px) translateY(20px) scaleX(-1);
    }
    48% {
        transform: translateX(90px) translateY(20px) scaleX(-1);
    }
    56% {
        transform: translateX(145px) translateY(20px) scaleX(-1);
    }
    64% {
        transform: translateX(0px) translateY(40px) scaleX(-1);
    }
    72% {
        transform: translateX(50px) translateY(40px) scaleX(-1);
    }
    80% {
        transform: translateX(90px) translateY(40px) scaleX(-1);
    }
    88% {
        transform: translateX(145px) translateY(40px) scaleX(-1);
    }
    100% {
        transform: translateX(0px) scaleX(-1);
    }

}

.line-1 {
    opacity: 0;
    animation: 2.5s linear 0s infinite normal forwards running line-1-animation;
    transform-box: fill-box;
    transform-origin: 50% 50%;

}

@keyframes line-1-animation {
    8% {
        opacity: 0;
    }
    60% {
        opacity: 1;
    }


}

.line-2 {
    opacity: 0;
    animation: 2.5s linear 0s infinite normal forwards running line-2-animation;
    transform-box: fill-box;
    transform-origin: 50% 50%;
}

@keyframes line-2-animation {
    48% {
        opacity: 0;
    }
    80% {
        opacity: 1;
    }
}

.line-3 {
    opacity: 0;
    animation: 2.5s linear 0s infinite normal forwards running line-3-animation;
    transform-box: fill-box;
    transform-origin: 50% 50%;
}

@keyframes line-3-animation {
    80% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
