
/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}


html {
    overflow-x: hidden;
    margin-top: 56px; /* margin to account for fixed navigation bar*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;
    display: block;
    border: 0;
}
.navbar {
    display: flex;
    padding: 0;
}

.navbar > .nav-link {
    align-self: flex-end !important;
}

img svg {
    user-select: none;
}
